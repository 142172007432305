$full-height: 100vh;
$full-width: 100%;
// $background: linear-gradient(90deg, rgba(22,0,59,1) 0%, rgba(33,33,62,1) 29%, rgba(11,16,17,1) 100%);
// $background: linear-gradient(90deg, rgb(41, 28, 5) 89%, rgb(62, 53, 33) 100%, rgb(17, 15, 11) 100%);
$background: radial-gradient(circle, rgb(52, 46, 38) 44%, rgb(28, 21, 2) 86%);
$font-family: cursive;
$borderColor: 5px ridge rgb(163, 120, 19);
$borderColor2: 2px ridge rgb(163, 120, 19);
$borderColor2-1: 5px solid rgb(163, 120, 19);
$bet-place-border: 2px solid rgb(255, 150, 150);
$backgroundColor: rgb(46, 30, 4);
// $borderColor: 5px ridge rgb(249, 70, 249);
// $backgroundColor: rgb(56, 10, 74);
// $borderColor: 5px ridge rgb(0, 154, 5);

body{
    background: $background;
}

.header-four-container{
    .header-row{
        padding: 2em;
        padding-bottom: 0;
        font-family: $font-family;
        &.label{
            padding: 0;
            font-size: xx-large;
            font-weight: bold;
            color: rgb(206, 182, 0);
            text-shadow: 2px 2px 5px rgba(212,175,55,0.3);
        }
        &.button{
            padding: 0.5em;
            text-align: right;
            button{
                padding: 0.5em 2em;
                font-size: small;
                font-weight: bold;
                background-color: transparent;
                border-radius: 1em;
                border: 5px solid #D4AF37;
                color: #D4AF37;
                box-shadow: 0px 3px 18px 1px rgba(212,175,55,0.3);
            }

        }
    }
}

.header-five-container{
    .betting-detail{
        margin: 0.2em 0.1em;
        padding: 0.2em 0.5em;
        background-color: black;
        border: $borderColor2;
        border-radius: 0.5em;
        color: #979799;
        text-align: left;
    }
}

.home-container{
    padding: 2em !important;
    height: 70em;
    .bet-container{
        // margin-top: 2.5em;
        -webkit-box-shadow: inset 2px 200px 300px -64px rgba(0,0,0,0.75);
        -moz-box-shadow: inset 2px 200px 300px -64px rgba(0,0,0,0.75);
        // box-shadow: inset 2px 200px 300px -64px rgba(0,0,0,0.75);
        border-radius: 50px;
        background: $backgroundColor;
        box-shadow:  5px 5px 25px #080501,
                     -5px -5px 25px #d09940;
        height: 13em;
        border: $borderColor;
        border-radius: 1em;
        position: relative;

        label{
            font-family: Lucida Handwriting;
            color: rgb(206, 182, 0);
            font-size: large;
            font-weight: bold;
            position: absolute;
            margin: 1em;
        }
        .bet-point{
            color: bisque;
            font-family: Lucida Handwriting;
            font-size: large;
            font-weight: bold;
            position: absolute;
            margin: 1em;
            margin-top: 2.5em;

        }
        button{
            background: $backgroundColor;
            border: 2px solid #D4AF37 ;
            border-radius: 2em;
            color: #D4AF37;
            font-size: small;
            font-weight: 700;
            position: absolute;
            bottom: 0;
            margin: 2em;
        }
        .timer{
            position: absolute;
            right: 0;
            padding: 1.5em 2em;
            text-align: center;
            div{
                svg{
                    background: $backgroundColor;
                    border-radius: 50%;
                }
            }
        }
    }
    .jackpot-container{
        margin-top: 2.5em;
        // background: $backgroundColor;
        border-radius: 50px;
        background: linear-gradient(145deg, #69460e, #583b0c);
        box-shadow:  5px 5px 25px #080501,
                     -5px -5px 25px #d09940;
        height: 15em;
        border: $borderColor;
        border-radius: 1em;
        position: relative;
        .jackpot-player-label{
            color: azure;
            font-size: medium;
            padding-left: 2em;
            padding-top: 0.5em;
        }
        .jackpot-ticker{
            list-style-type: none; /* Remove bullets */
            width: 40%;
            height: 11em !important;
            // margin-top: 1em;
            padding-left: 1em;
            .jackpot-ticker-row{
                background-color: rgba(98, 65, 13, 0.5);
                // border: 2px solid #D4AF37 ;
                border-radius: 0.5em;
                padding: 0 0.5em;
                margin: 0.5em 0em;
                span{
                    color: rgb(169, 169, 169);
                }
            }
        }
        .countup{
            margin-right: 5em ;
            position: absolute;
            top: 4.2em;
            right: 0;
            color: #D4AF37;
            font-size: medium;
            font-weight: bold;
        }
        .banner{
            margin-right: 2em ;
            position: absolute;
            top: -3.5em;
            right: 0;
        }       
        .neonBox{
            margin-right: 1em ;
            position: absolute;
            bottom: 6em;
            right: 0;
        } 
        .background1{
            margin-right: 1em ;
            position: absolute;
            bottom: 0em;
            right: 0;
        }
        label{
            font-family: Lucida Handwriting;
            font-size: x-large;
            font-weight: bold;
            position: absolute;
            margin: 1em;
        }
    }
    .zodiac-container{
        margin-top: 2.5em;
        // background: $backgroundColor;
        border-radius: 50px;
        background: $backgroundColor;
        box-shadow:  5px 5px 25px #080501,
                     -5px -5px 25px #d09940;
        height: 15em;
        border: $borderColor;
        border-radius: 1em;
        position: relative;
        .zodiac-result{
            display: block;
            position: absolute;
            top: 14%;
            right: 0;
            left: 0;
            width: 60%;

            .zodiac-row{
                z-index: 0;
                margin: 0.5em;
                padding: 0.5em;
                background: rgb(37, 21, 37);
                color: rgb(206, 182, 0);
                border: 2px solid #D4AF37;
                border-radius: 0.5em;
                box-shadow: 0px 3px 18px 0.5px rgba(209, 55, 212, 0.3);

            }
        }
        .dragon{
            z-index: 1;
            position: absolute;
            top: -2em;
            left: 3em;
        }
        .chicken{
            position: absolute;
            bottom: 5em;
            left: 0em;
        }
        .snake{
            z-index: 1;
            position: absolute;
            bottom: 0em;
            left: 3.5em;
        }

    }
    .influencer-container{
        // margin-top: 2.5em;
        height: 15em;
        position: relative;
        .influencer{
            background: $backgroundColor;
            border-radius: 50px;
            background: $backgroundColor;
            box-shadow:  5px 5px 25px #080501,
                         -5px -5px 25px #d09940;
            margin: 1em;
            width: 30%;
            height: 10em;
            margin-top: 2.5em;
            border: $borderColor;
            border-radius: 1em;
        }
    }
}

.wallet-container{
    .wallet-card{
        background: rgb(161,40,40);
        background: linear-gradient(113deg, rgba(161,40,40,1) 0%, rgba(255,150,65,1) 100%);
        box-shadow: 5px 5px 16px 0px rgb(95, 88, 88);
        color: #e1e0e0;
        height: 15em;
        border-radius: 0.5em;
        margin: 2em;
        padding: 0.5em 0em;
        .wallet-card-title{
            display: block;
            .wallet-card-name{
                font-size: medium;
                margin: 1em;
                height: 1.5em;
                // align-items: end;
                margin-bottom: 0em;
            }
            .wallet-card-number{
                font-size: medium;
                margin: 0em 1em;
            }
        }
        .wallet-card-amount{
            font-size: xx-large;
            align-items: end;
            margin: 0.5em;
            span{
                img{
                    padding: 0 0.5em 0 0;
                }
                display: flex;
                padding: 0;
                align-items: center;
            }
        }
    }
    .wallet-action{
        margin: 2em;
        .action-btn{
            display: flex;
            justify-content: center;
            padding: 0;
            .button{
                background-color: rgb(34, 25, 9);
                color: grey;
                border: 2px ridge rgb(79, 57, 8);
                border-radius: 0.4em;
                padding: 0.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10em;
            }
            .button-active{
                background-color: $backgroundColor;
                border: $borderColor2;
                border-radius: 0.4em;
                padding: 0.5em;
                color: #FBFBFB;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10em;
            }
        }
    }
    .topup-container{
        background-color: $backgroundColor;
        border: $borderColor2;
        border-radius: 0.4em;
        margin: 2em;
        padding: 2em;
        .topup-select-bank{
            margin-bottom: 1em;
            .bank-label{
                font-size: medium;
                text-align: end;
                color: #a17d06;
            }
            .bank{
                select{
                    background: transparent;
                    border: $borderColor2;
                    color: white;
                    height: 2em;
                    border-radius: 0.5em;
                    font-size: medium;
                }
            }
        }
        .topup-detail{
            margin: 1em 0em;
            .topup-label{
                font-size: medium;
                text-align: end;
                color: #a17d06;
            }
            .custom-field{
                input{
                    background: transparent;
                    border: $borderColor2;
                    color: white;
                    height: 2em;
                    border-radius: 0.5em;
                    font-size: medium;
                }
            }
            .bank-info{
                .custom-field{
                    input{
                        background: transparent;
                        border: none;
                        color: white;
                        height: 2em;
                        border-radius: 0.5em;
                        font-size: medium;
                    }
                }
            }

        }
        .topup-btn{
            margin-top: 2em;
            .custom-button{
                display: flex;
                justify-content: center;
                padding: 0;
                button{
                    background-color: $backgroundColor;
                    border: $borderColor2;
                    box-shadow: 0px 0px 16px 0px rgb(19, 17, 17);
                    border-radius: 0.4em;
                    padding: 0.5em;
                    color: #FBFBFB;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 10em;
                }
            }
        }
    }
}

.bet-page{
    // background: #080501;
    .betting-container{
        .bet-place-container{
            margin-top: 2em;
            perspective: 100em;
            display: flex;
            justify-content: center;
            align-items: center;
            .bet-table{
                position: absolute;
                top: 1em;
                left: 2em;
                div{
                    position: relative;
                    .bet-table-label{
                        position: absolute;
                        left: 18px;
                        color: rgb(163, 120, 19);
                        top: 12px;
                        width: 8em;
                        background: transparent;
                        border: 0;
                    }
                }
            }
            .bet-place {
                padding: 0.8em;
                width: 410px;
                height: 550px;
                background:rgb(118, 12, 12);
                border: $bet-place-border;
                transform:rotateX(35deg);
                // margin:100px auto;
                .wrap{
                    width: 10em;
                    height: 5em;
                    border: $bet-place-border;
                    position: relative;
                    margin: 5px;
                    // background-image: linear-gradient(133deg, #760c0c 20%, #ff9696 20%, #ff9696 50%, #760c0c 50%, #760c0c 70%, #ff9696 70%, #ff9696 100%);
                    // background-size: 6.84px 7.33px;
                }
                .border-color{
                    padding: 0;
                    display: flex;
                    height: 4em;
                    background-image: linear-gradient(133deg, #760c0c 20%, #ff9696 20%, #ff9696 50%, #760c0c 50%, #760c0c 70%, #ff9696 70%, #ff9696 100%);
                    background-size: 6.84px 7.33px;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    font-size: medium;
                    margin: 2px;
                    .bet-chip{
                        // background:rgb(118, 12, 12);
                        // color: rgb(255, 150, 150);
                        // font-size: small;
                        // font-weight: bold;
                        // padding: 1.5em 0.5em;
                        position: absolute;
                        .chip-label{
                            display: flex;
                            background: rgb(0 0 0 / 50%);
                            color: rgb(255, 225, 0);
                            border-radius: 1em;
                            justify-content: center;
                            font-size: small;
                            margin-bottom: 0.2em;
                        }
                    }
                    span{
                        background:rgb(118, 12, 12);
                        color: rgb(255, 150, 150);
                        font-size: small;
                        font-weight: bold;
                        padding: 1em 1.1em;
                    }
                    .animal{
                        background:rgb(118, 12, 12);
                        color: rgb(255, 150, 150);
                        font-size: small;
                        font-weight: bold;
                        padding: 1em 4em;
                    }
                    .special-text{
                        background:rgb(118, 12, 12);
                        color: rgb(255, 150, 150);
                        font-size: small;
                        font-weight: bold;
                        padding: 1em 4.5em;
                    }
                }
                .bet-place-label{
                    padding: 1em 2em;
                    display: flex;
                    font-size: medium;
                    justify-content: center;
                    color: rgb(255, 150, 150);
                    div{
                        // width: 6em;
                        padding: 0em 5em;
                        border-bottom: 1px solid rgb(255, 150, 150);
                    }
                }
            }
        }
        .bet-result-container{
            .dice{
                display: flex;
                // justify-content: end;
            }
        }
    }
}

.promotion-container{
    .react-multi-carousel-list{
        height: 40em;
        // padding: 3em;
        background: rgba(105, 73, 5, 0.4);
        border: $borderColor;
        border-radius: 0.5em;
        margin: 3em 2em 0em 2em;
        div{
            // min-width: 20em;
            // height: 40em;
            // // padding: 3em;
            // background: rgba(105, 73, 5, 0.4);
            // border: $borderColor;
            // border-radius: 0.5em;
            // margin: 3em 5em 0em 2em;
        }
        button{
            background: $backgroundColor;
            border: $borderColor2;
        }
        button:before{
            color: #d09940;
        }
    }
}

.profile-container{
    padding: 0;
    margin-top: 1.5em;
    padding-bottom: 10em;
    .profile-picture{
        justify-content: center;
        height: 9em;
        position: relative;
        .profile-picture-col{
            border: $borderColor2-1;
            background: $backgroundColor;
            border-radius: 50%;
            width: 10em;
            height: 8em;
            /* margin: 0 8em; */
            position: absolute;
            .profile-title{
                width: 5em;
                border: $borderColor2;
                border-radius: 50px;
                background: brown;
                box-shadow:  5px 5px 5px #080501;
                border-radius: 0.5em;
                color: gold;
                font-weight: bold;
                position: absolute;
                right: -2em;
                text-align: center;
            }
        }
    }
    .profile-detail{
        color: #D4AF37;
        font-size: x-large;
        text-align: center;
    }
    .profile-box-container{
        background: $backgroundColor;
        border: $borderColor;
        border-radius: 0.5em;
        padding: 1em;
        margin: 1em;
        .profile-box-title{
            font-size: medium;
            font-weight: 600;
            color: #a17d06;
        }
        .profile-box{
            margin-top: 0.5em;
            background: rgb(88, 59, 12,0.3);
            border-radius: 1.5em;
            &.history{
                background: transparent;
                padding: 0;
                .profile-box-row{
                    margin: 0.5em 0em;
                    background: rgb(88, 59, 12,0.3);
                    border-radius: 1.5em;
                    .profile-box-btn{
                        text-align: end;
                    }
                }
            }
            .profile-box-row{
                padding: 0.5em;
                .profile-box-label{
                    text-align: end;
                    font-size: medium;
                    color: #a17d06;
                }
                .profile-box-text{
                    color: #D4AF37;
                }
            }

        }
    }
}

.navbar-bottom{
    text-align: center;
    background: transparent !important;
    margin: 1em;
    margin-bottom: 0.5em;
    .navbar-container{
        padding: 2em 0em;
        background: rgba(29, 28, 26, 0.9);
        border-radius: 2em;
        .blink {
            position: absolute;
            right: 0;
            background-color: #D4AF37;
            border-radius: 50%;
            padding: 3em;
            opacity: 0.3;
            margin-top: 0em;
            margin: 1em;
            animation: blink-animation 2s steps(5, start) infinite;
            -webkit-animation: blink-animation 2s steps(5, start) infinite;
          }
          @keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
          @-webkit-keyframes blink-animation {
            to {
              visibility: hidden;
            }
          }
        .navbar-col{
            &.gift{
                position: absolute;
                right: 0;
                background-color: #D4AF37;
                border-radius: 50%;
                padding: 0.7em;
                margin: 1.67em;
            }
            .active{
                font-size: medium;
                color: #D4AF37;
                font-weight: bold;
            }
            .navbar-label{
                font-size: medium;
                font-weight: bold;
                color: #D4AF37;
                opacity: 0.5;
            }

        }
  
         
    }
    .navbar-two-container{
        display: flex;
        flex-wrap: inherit;
        align-items: center;
        justify-content: center;
        padding-left: 2px;
        margin-bottom: 2em;
        .betting-detail{
            margin: 0.2em 0.1em;
            width: 14em;
            padding: 0.2em 0.5em;
            background-color: black;
            border: $borderColor2;
            border-radius: 0.5em;
            color: #979799;
            text-align: left;
        }
        button {
            position: relative;
            font-size: 16px;
            padding: 0 20px;
            display: inline-block;
            border: none;
            outline: none;
            background:#FFF;
            border-radius: 3px;
            border: 1px solid #1F1F1F;
            box-shadow: 0 1px 3px 0px rgba(#0F0F0F, .5),
              inset 0 1px rgba(#FFF, .2); 
        }
          
        .buttonRound {
            height: 5em;
            width: 5em;
            border-radius: 100%;
        }
          
        .buttonDark {
            background: linear-gradient(#737373, #333333);
            background-size: auto 150%;
            background-position: 0 100%;
            
              &:hover {background-position: 0 50%;}
            
            &:active {
              color: #000;
              background-position: 0 50%;
              background: #333333;
              box-shadow: inset 0 2px 3px rgba(black, .5), 0 1px 1px rgba(white, .1);
              
              .label:before {
                color: #111;
                text-shadow: 0 1px rgba(white, .1);
              }
            }
        }
          
        .label:before {
            font-style: normal;
            font-size: small;
            display: block;
            margin-top: -5px;
            color: #DDD;
            text-shadow: 0 1px 1px rgba(black, 1);
            content: attr(data-char);
        }  

        .buttonRound2 {
            height: 4.5em;
            width: 4.5em;
            border-radius: 100%;
        }
          
        .buttonDark2 {
            background: linear-gradient(#737373, #333333);
            background-size: auto 150%;
            background-position: 0 100%;
            margin: 5px;
            
              &:hover {background-position: 0 50%;}
            
            &:active {
              color: #000;
              background-position: 0 50%;
              background: #333333;
              box-shadow: inset 0 2px 3px rgba(black, .5), 0 1px 1px rgba(white, .1);
              
              .label2:before {
                color: #111;
                text-shadow: 0 1px rgba(white, .1);
              }
            }
        }
          
        .label2:before {
            font-style: normal;
            font-size: x-small;
            display: block;
            margin-top: -5px;
            color: #DDD;
            text-shadow: 0 1px 1px rgba(black, 1);
            content: attr(data-char);
        }  
    }
}
h1 {
	text-align: center;
  }
  .main-container {
	max-width: 800px;
	margin: 0 auto;
  }
  .main-dice-container {
	padding: 50px;
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
  }
  .dice-container {
	margin: 0.2em;
	height: 2.5em;
	width: 2.5em;
	perspective: 1000px;
  }
  .dice {
	width: 2.5em;
	height: 2.5em;
	transform-style: preserve-3d;
	transition: all 200ms;
  }
  .dice.face-1 {
	transform: rotateX(0);
  }
  .dice.face-2 {
	transform: rotateY(90deg);
  }
  .dice.face-3 {
	transform: rotateX(-90deg);
  }
  .dice.face-4 {
	transform: rotateX(90deg);
  }
  .dice.face-5 {
	transform: rotateY(-90deg);
  }
  .dice.face-6 {
	transform: rotateX(-180deg);
  }
  .dot-container {
	display: grid;
	grid-template-columns: repeat(3, 0.45em);
	grid-template-rows: repeat(3, 0.45em);
	grid-gap: 0.1125em 0.1125em;
  }
  .dot {
	background-color: black;
	border-radius: 50%;
  }
  .dice [class^="face"] {
	position: absolute;
	width: 2.5em;
	height: 2.5em;
	outline: 2px solid #00bbff;
	outline-offset: -2px;
	background-color: rgba(255, 255, 255, 0.9);
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .dice .face-1 {
	transform: translateX(0) translateY(0) translateZ(1.25em);
  }
  .dice .face-1 .dot-container .dot {
	grid-column: 2 / span 1;
	grid-row: 2 / span 1;
  }
  .dice .face-3 {
	transform: translateX(0) translateY(-50%) translateZ(0) rotateX(90deg);
  }
  .dice .face-3 .dot-container .dot:nth-child(1) {
	grid-column: 3 / span 1;
	grid-row: 1 / span 1;
  }
  .dice .face-3 .dot-container .dot:nth-child(2) {
	grid-column: 2 / span 1;
	grid-row: 2 / span 1;
  }
  .dice .face-3 .dot-container .dot:nth-child(3) {
	grid-column: 1 / span 1;
	grid-row: 3 / span 1;
  }
  .dice .face-2 {
	transform: translateX(-50%) translateY(0%) translateZ(0) rotateY(90deg);
  }
  .dice .face-2 .dot-container .dot:nth-child(1) {
	grid-column: 3 / span 1;
	grid-row: 1 / span 1;
  }
  .dice .face-2 .dot-container .dot:nth-child(2) {
	grid-column: 1 / span 1;
	grid-row: 3 / span 1;
  }
  .dice .face-4 {
	transform: translateX(0) translateY(50%) translateZ(0) rotateX(90deg);
  }
  .dice .face-4 .dot-container .dot:nth-child(1) {
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
  }
  .dice .face-4 .dot-container .dot:nth-child(2) {
	grid-column: 1 / span 1;
	grid-row: 3 / span 1;
  }
  .dice .face-4 .dot-container .dot:nth-child(3) {
	grid-column: 3 / span 1;
	grid-row: 3 / span 1;
  }
  .dice .face-4 .dot-container .dot:nth-child(4) {
	grid-column: 3 / span 1;
	grid-row: 1 / span 1;
  }
  .dice .face-5 {
	transform: translateX(50%) translateY(0%) translateZ(0) rotateY(90deg);
  }
  .dice .face-5 .dot-container .dot:nth-child(1) {
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
  }
  .dice .face-5 .dot-container .dot:nth-child(2) {
	grid-column: 1 / span 1;
	grid-row: 3 / span 1;
  }
  .dice .face-5 .dot-container .dot:nth-child(5) {
	grid-column: 2 / span 1;
	grid-row: 2 / span 1;
  }
  .dice .face-5 .dot-container .dot:nth-child(3) {
	grid-column: 3 / span 1;
	grid-row: 3 / span 1;
  }
  .dice .face-5 .dot-container .dot:nth-child(4) {
	grid-column: 3 / span 1;
	grid-row: 1 / span 1;
  }
  .dice .face-6 {
	transform: translateX(0) translateY(0) translateZ(-1.25em);
  }
  .dice .face-6 .dot-container .dot:nth-child(1) {
	grid-column: 1 / span 1;
	grid-row: 1 / span 1;
  }
  .dice .face-6 .dot-container .dot:nth-child(2) {
	grid-column: 3 / span 1;
	grid-row: 3 / span 1;
  }
  .dice .face-6 .dot-container .dot:nth-child(3) {
	grid-column: 1 / span 1;
	grid-row: 3 / span 1;
  }
  .dice .face-6 .dot-container .dot:nth-child(4) {
	grid-column: 1 / span 1;
	grid-row: 2 / span 1;
  }
  .dice .face-6 .dot-container .dot:nth-child(5) {
	grid-column: 3 / span 1;
	grid-row: 1 / span 1;
  }
  .dice .face-6 .dot-container .dot:nth-child(6) {
	grid-column: 3 / span 1;
	grid-row: 2 / span 1;
  }
  .button-container {
	display: flex;
	justify-content: center;
  }
  .dan-btn {
	text-decoration: none;
	display: inline-block;
	padding: 8px 10px;
	border-radius: 3px;
	background-color: #dddddd;
	color: #444;
	cursor: pointer;
  }
  .dan-btn[disabled] {
	opacity: 0.4;
  }
  .dan-btn--default {
	background-color: #dddddd;
	color: #444;
  }
  